import React from "react";
import _ from 'lodash';
import { buildEmptyObject } from "../../../../auto/js/widgets";

export const affiliationApplicationFields = [
	{name: "id", type:"number", x:1, y:1, layout:"col-md-6"},
	{name:"recordId", type:"text", x:2, y:1, layout:"col-md-6"},
    {name:"name", type:"text", x:2, y:1, layout:"col-md-6"},
    {name: "submissionDate", type: "date", x:3, y:1, layout:"col-md-6"},
    {name: "issuedBy", type: "text", x:12, y:1, layout:"col-md-6"}

];

export const buildAffiliationApplicationFormEmptyObject  = () => {
    const empty = buildEmptyObject(affiliationApplicationFields);
	return empty;
}

export const form2dto = (formData, dto) => {
	if(formData.submissionDate != null && typeof(formData.submissionDate) != 'string') {
		let submissionDateAsDate = new Date(formData.submissionDate);
		dto.submissionDate = submissionDateAsDate.getFullYear() + "-" + ('0' + (submissionDateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + submissionDateAsDate.getDate()).slice(-2);
	}
}

export const dto2form = (dto) => {
	return dto;
}