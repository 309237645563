import React from "react";

export const appointmentFields = [
	{name: "ppt", type:"text", x:1, y:1, layout:"col-md-6"},
    {name:"date", type:"date", x:2, y:1, layout:"col-md-6"},
    {name: "fromTime", type: "time", x:3, y:1, layout:"col-md-6"},
    {name: "toTime", type: "time", x:3, y:2, layout:"col-md-6"}

];

export const form2dto = (formData, dto) => {
	if(formData.date != null && typeof(formData.date) != 'string') {
		let dateAsDate = new Date(formData.date);
		dto.date = dateAsDate.getFullYear() + "-" + ('0' + (dateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateAsDate.getDate()).slice(-2);
	}

	if (formData.fromTime != null && typeof(formData.fromTime) != 'string') {
		const date = new Date(formData.fromTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.fromTime = birthHour + ":" + birthMinute;
	}
    if (formData.toTime != null && typeof(formData.toTime) != 'string') {
		const date = new Date(formData.toTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.toTime = birthHour + ":" + birthMinute;
	}

}

export const dto2form = (dto) => {
	if (dto.fromTime !== null) {
		dto.fromTime = new Date().setHours(dto.fromTime[0], dto.fromTime[1]);
	}
	if (dto.toTime !== null) {
		dto.toTime = new Date().setHours(dto.toTime[0], dto.toTime[1]);
	}
	return dto
}