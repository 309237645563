import React from "react";

export const programFields = [
	{name: "name", type:"text", x:1, y:1, layout:"col-md-6"},
    {name:"description", type:"text", x:1, y:2, layout:"col-md-6"},
    {name: "fromDate", type: "date", x:2, y:1, layout:"col-md-6"},
    {name: "toDate", type: "date", x:2, y:2, layout:"col-md-6"},
    {name: "genderApproach", type: "checkbox", x:3, y:1, layout:"col-md-3"},
    {name: "ethnicFocus", type: "checkbox", x:4, y:1, layout:"col-md-3"},
    {name: "availableSpace", type: "number", x:5, y:1, layout:"col-md-3"},
    {name: "pptCopy", type: "checkbox", x:7, y:1, layout:"col-md-12"},
    {name: "form234J", type: "checkbox", x:8, y:1, layout:"col-md-12"},
    {name: "appointment", type: "checkbox", x:9, y:1, layout:"col-md-12"},
    {name: "enterRequirement", type: "checkbox", x:10, y:1, layout:"col-md-12"},
    {name: "serviceAddress", type: "text", x:12, y:1, layout:"col-md-6"},
    {name: "contact", type: "text", x:12, y:2, layout:"col-md-6"}

];