import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import { displayNewProgramForm } from '../../../auto/js/forms/program/ProgramForm';
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import { displayProgramList } from '../../../auto/js/lists/program/ProgramList';
import { displayAffiliationApplicationList } from '../../../auto/js/lists/affiliationApplication/AffiliationApplicationList';
import { SIDE_MENU } from '../../../auto/js/metadata/MenuType';
import {whoami} from '../../../auto/js/users/UserInfo';
import { displayAppointmentList } from '../../../auto/js/lists/appointment/AppointmentList';
import { displayNewAppointmentForm } from '../../../auto/js/forms/appointment/AppointmentForm';
import { displayNewAffiliationApplicationForm } from '../../../auto/js/forms/affiliationApplication/NewAffiliationApplicationForm';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "affiliation": {
                submenu: {
                    "affiliation": {
                        options: {
                            list: {label: "Solicitudes de afiliación", do: displayAffiliationApplicationList},
                            new: {label: "New afiliación", do: () => displayNewAffiliationApplicationForm(null)}
                        },
                        label: "Solicitudes de afiliación"
                    }
                },
                label: "Solicitudes de afiliación"
            },
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "configuration": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New Translation", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                },
                label: "Configuration"
            }
        })
    }
}
export const menuType = SIDE_MENU;